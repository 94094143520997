import "./ContentSection4.scss";
import { ImageViewer } from "react-image-viewer-dv";

import pyreIcon from "../../img/pyreIcon-152x152.png";
import stakingDiagram from "../../img/stakingDiagram.png";
import iwvTree from "../../img/iwvTree.png";

const ContentSection4 = () => {
    return (
        <>
            <div className="section4HeaderWrapper">
                <img
                    src={pyreIcon}
                    alt="pyreswap icon"
                    className="section4HeaderIcon"
                />
                <div className="section4Header">
                    Active Staking: Concept and Strategy
                </div>
            </div>

            <div className="section4Wrapper">
                <div className="section4ImgWrapper section4ImgWrapper1">
                    <ImageViewer>
                        <img
                            src={stakingDiagram}
                            alt="staking diagram"
                            className="section4Img"
                        />
                    </ImageViewer>
                    <div className="clickImgText">Click to Expand</div>
                </div>

                <div className="section4ImgWrapper">
                    <ImageViewer>
                        <img
                            src={iwvTree}
                            alt="iwv tree"
                            className="section4Img"
                        />
                    </ImageViewer>
                    <div className="clickImgText">Click to Expand</div>
                </div>
            </div>
        </>
    );
};

export default ContentSection4;
