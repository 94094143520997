import "./ContentSectionVideo.scss";

import pyreIcon from "../../img/pyreIcon-152x152.png";

const ContentSectionVideo = () => {
    return (
        <>
            <div className="sectionVideoHeaderWrapper">
                <img src={pyreIcon} alt="pyreswap icon" className="sectionVideoHeaderIcon" />
                <div className="sectionVideoHeader">What is PyreSwap?</div>
            </div>

            <div className="sectionVideoWrapper">
                <iframe
                    className="sectionVideoFrame"
                    width="616"
                    height="347"
                    src="https://www.youtube.com/embed/7Iq56HDvQNU?si=zaCQogxCnJ5ryMTG"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>

                <iframe
                    className="sectionVideoFrame"
                    width="616"
                    height="347"
                    src="https://www.youtube.com/embed/oeA-KR1gTvk?si=TBdCLJakzf0SOYRb"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>

                <iframe
                    className="sectionVideoFrame"
                    width="616"
                    height="347"
                    src="https://www.youtube.com/embed/VcYFZs6hUNU?si=J_LpztFNYTSytfa3"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>

                <iframe
                    className="sectionVideoFrame"
                    width="616"
                    height="347"
                    src="https://www.youtube.com/embed/tdiB-QMsQBo?si=9zHOTrW4-lTv6hVN"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
        </>
    );
};

export default ContentSectionVideo;
