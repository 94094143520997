import "./StartInfo.scss";

import { useEffect, useState } from "react";
import axios from "axios";
import CountUp from "react-countup";

import titleImg from "../../img/title.png";

import Skeleton from "@mui/material/Skeleton";

const StartInfo = () => {
    const [tvl, setTvl] = useState();
    const [loadingTvl, setLoadingTvl] = useState(true);
    const [errorTvl, setErrorEpoch] = useState(false);
    const [animate, setAnimate] = useState(false);

    const errorMsg = "Error!";

    const startAnimate = () => {
        setAnimate(true);
    };
    const stopAnimate = () => {
        setAnimate(false);
    };
    const containerProps = {
        "aria-busy": animate,
    };

    useEffect(() => {
        setErrorEpoch(false);
        setLoadingTvl(true);
        axios
            .get("https://api.llama.fi/tvl/pyreswap")
            .then((res) => {
                setTvl(Math.round(res.data));
            })
            .catch((e) => {
                setErrorEpoch(true);
                console.log(e);
            })
            .finally(() => {
                setLoadingTvl(false);
            });
    }, []);

    // phrase animation
    useEffect(() => {
        const words = document.querySelectorAll(".startInfoPhrase");
        const wordArray = [];
        let currentWord = 0;

        // words[currentWord].style.opacity = 1;

        for (let i = 0; i < words.length; i++) {
            splitLetters(words[i]);
        }

        function changeWord() {
            const cw = wordArray[currentWord];
            const nw = currentWord === words.length - 1 ? wordArray[0] : wordArray[currentWord + 1];

            for (let i = 0; i < cw.length; i++) {
                animateLetterOut(cw, i);
            }

            for (let i = 0; i < nw.length; i++) {
                nw[i].className = "letter behind";
                nw[0].parentElement.style.opacity = 1;
                animateLetterIn(nw, i);
            }

            currentWord = currentWord === wordArray.length - 1 ? 0 : currentWord + 1;
        }

        function animateLetterOut(cw, i) {
            setTimeout(function () {
                cw[i].className = "letter out";
            }, i * 30);
        }

        function animateLetterIn(nw, i) {
            setTimeout(function () {
                nw[i].className = "letter in";
            }, 340 + i * 30);
        }

        function splitLetters(word) {
            const content = word.innerHTML;
            word.innerHTML = "";
            const letters = [];
            for (let i = 0; i < content.length; i++) {
                const letter = document.createElement("span");
                letter.className = "letter";
                letter.innerHTML = content.charAt(i) === " " ? content.charAt(i) + "&nbsp;" : content.charAt(i);
                word.appendChild(letter);
                letters.push(letter);
            }

            wordArray.push(letters);
        }

        changeWord();
        setInterval(changeWord, 3000);
    }, []);

    const skeleton = (width, height) => {
        return <Skeleton className="mainSkeleton" width={width} height={height} />;
    };

    return (
        <>
            <img src={titleImg} alt="title" className="startInfoTitleImg" />

            <div className="startInfoPhraseWrapper">
                <div className="startInfoPhraseFirst">The mega-dApp that</div>
                <div>
                    <span class="startInfoPhrase">
                        shows you the potential of GameFi x DeFi, now and in the future.
                    </span>
                    <span class="startInfoPhrase">lets you monetize your wallet's transaction history.</span>
                    <span class="startInfoPhrase">gives you the same powers as central bankers.</span>
                    <span class="startInfoPhrase">
                        makes you a member in the world's first decentralized credit union.
                    </span>
                    <span class="startInfoPhrase">builds your wallet's credit score while you play games.</span>
                    <span class="startInfoPhrase">
                        offers you the highest real yields in all of DeFi, consistently.
                    </span>
                    <span class="startInfoPhrase">makes you first in line for the newest DeFi inventions.</span>
                    <span class="startInfoPhrase">teaches you where the money comes from, and how to make it.</span>
                    <span class="startInfoPhrase">helps you believe in something bigger than yourself.</span>
                    <span class="startInfoPhrase">empowers you to use community and teamwork to leverage profits.</span>
                </div>
            </div>

            <div className="startInfoTvlWrapper">
                <div className="startInfoTvl">
                    {!errorTvl && tvl ? (
                        <div className="startInfoTvlValueWrapper">
                            <div>TVL: $</div>
                            <CountUp
                                start={0}
                                end={tvl}
                                duration="3"
                                onStart={startAnimate}
                                onEnd={stopAnimate}
                                containerProps={containerProps}
                            />
                        </div>
                    ) : loadingTvl ? (
                        skeleton(150, 36)
                    ) : (
                        `TVL: ${errorMsg}`
                    )}
                </div>
            </div>

            <div className="startInfoTvb hidden">TVB: $???</div>
        </>
    );
};

export default StartInfo;
