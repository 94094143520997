import "./ContentSectionEnd.scss";

import cloakedMan from "../../img/cloakedMan.png";

import { Button } from "@mui/material";

const ContentSectionEnd = () => {
    return (
        <>
            <div className="sectionEndHeader">Don't miss an update!</div>
            <div className="sectionEndBtnWrapper">
                <Button
                    variant="contained"
                    className="sectionEndBtn"
                    href="https://discord.gg/YHFwazH3pW"
                    target="_blank"
                >
                    Join Discord
                </Button>
                <Button
                    variant="contained"
                    className="sectionEndBtn"
                    href="https://twitter.com/pyreswap"
                    target="_blank"
                >
                    Follow Twitter
                </Button>
            </div>
            <div className="sectionEndImgWrapper">
                <img
                    src={cloakedMan}
                    alt="cloaked man"
                    className="sectionEndImg"
                />
            </div>
        </>
    );
};

export default ContentSectionEnd;
