import "./ContentSection2.scss";
import pyreIcon from "../../img/pyreIcon-152x152.png";

const ContentSection2 = () => {
    return (
        <>
            <div className="section2HeaderWrapper">
                <img
                    src={pyreIcon}
                    alt="pyreswap icon"
                    className="section2HeaderIcon"
                />
                <div className="section2Header">Roadmap</div>
            </div>

            {/* desktop */}
            <div className="section2Line">
                <div className="section2LineActive"></div>
            </div>

            <div className="section2Lines">
                <div className="section2VerticalLine section2VerticalLineActive">
                    <div className="section2LineCircle"></div>
                </div>
                <div className="section2VerticalLine"></div>
                <div className="section2VerticalLine"></div>
            </div>

            <div className="section2BlockWrapper">
                <div className="section2Block section2BlockActive">
                    <div className="section2BlockTitle">Phase I</div>
                    <ul className="section2BlockList">
                        <li>Concepts & Terminology</li>
                        <li>DEX Mechanisms</li>
                        <li>The Meeding System</li>
                        <li>Fundamental Analysis</li>
                        <li className="yellowText">Liquidity Genesis!</li>
                        <li>The Incantation & IWV!</li>
                        <li>Marketing & Promotion</li>
                        <li>Appointment of Captains</li>
                        <li>Liquidity Theoretics</li>
                        <li>Community Training</li>
                        <li>Connection of the GPSE</li>
                    </ul>
                </div>
                <div className="section2Block">
                    <div className="section2BlockTitle">Phase II</div>
                    <ul className="section2BlockList">
                        <li>Expansion & Upgrading</li>
                        <li>Liquidity Dashboarding</li>
                        <li>Advanced LP Capabilities!</li>
                        <li>Faction Status Dashboard</li>
                        <li className="yellowText">Cross-chain Farming!</li>
                        <li>Another WitchVault</li>
                        <li>The Lunary System</li>
                        <li>The Augury System</li>
                        <li>The Omen System</li>
                        <li>More Training!</li>
                        <li className="yellowText">The GPSE on More Chains!</li>
                    </ul>
                </div>
                <div className="section2Block">
                    <div className="section2BlockTitle">Phase III</div>
                    <ul className="section2BlockList">
                        <li>Terminal Utility: Quests</li>
                        <li>Event: Monsters</li>
                        <li>The Title System</li>
                        <li>Title Dashboarding</li>
                        <li className="yellowText">
                            Cross-chain Game Rewards!
                        </li>
                        <li>Event: Boss Battles</li>
                        <li>Terminal Utility: Arcade</li>
                        <li>Arcade Dashboarding</li>
                        <li>More Witchvaults!</li>
                        <li>More Training!</li>
                        <li className="yellowText">The GPSE on More Chains!</li>
                    </ul>
                </div>
            </div>

            {/* mobile */}
            <div className="section2WrapperMobile">
                <div className="section2LineMobile">
                    <div className="section2LineMobileActive"></div>
                </div>

                <div className="section2LinesMobile">
                    <div className="section2HorizontalLineMobile section2HorizontalLineMobileActive">
                        <div className="section2LineMobileCircle"></div>
                    </div>
                    <div className="section2HorizontalLineMobile"></div>
                    <div className="section2HorizontalLineMobile"></div>
                </div>

                <div className="section2BlockWrapperMobile">
                    <div className="section2BlockMobile section2BlockActiveMobile">
                        <div className="section2BlockTitleMobile">Phase I</div>
                        <ul className="section2BlockListMobile">
                            <li>Concepts & Terminology</li>
                            <li>DEX Mechanisms</li>
                            <li>The Meeding System</li>
                            <li>Fundamental Analysis</li>
                            <li className="yellowText">Liquidity Genesis!</li>
                            <li>The Incantation & IWV!</li>
                            <li>Marketing & Promotion</li>
                            <li>Appointment of Captains</li>
                            <li>Liquidity Theoretics</li>
                            <li>Community Training</li>
                            <li>Connection of the GPSE</li>
                        </ul>
                    </div>
                    <div className="section2BlockMobile">
                        <div className="section2BlockTitleMobile">Phase II</div>
                        <ul className="section2BlockListMobile">
                            <li>Expansion & Upgrading</li>
                            <li>Liquidity Dashboarding</li>
                            <li>Advanced LP Capabilities!</li>
                            <li>Faction Status Dashboard</li>
                            <li className="yellowText">Cross-chain Farming!</li>
                            <li>Another WitchVault</li>
                            <li>The Lunary System</li>
                            <li>The Augury System</li>
                            <li>The Omen System</li>
                            <li>More Training!</li>
                            <li className="yellowText">
                                The GPSE on More Chains!
                            </li>
                        </ul>
                    </div>
                    <div className="section2BlockMobile">
                        <div className="section2BlockTitleMobile">
                            Phase III
                        </div>
                        <ul className="section2BlockListMobile">
                            <li>Terminal Utility: Quests</li>
                            <li>Event: Monsters</li>
                            <li>The Title System</li>
                            <li>Title Dashboarding</li>
                            <li className="yellowText">
                                Cross-chain Game Rewards!
                            </li>
                            <li>Event: Boss Battles</li>
                            <li>Terminal Utility: Arcade</li>
                            <li>Arcade Dashboarding</li>
                            <li>More Witchvaults!</li>
                            <li>More Training!</li>
                            <li className="yellowText">
                                The GPSE on More Chains!
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContentSection2;
