import "./Timer.scss";
import { useState } from "react";

import ProgressCountdown from "./ProgressCountdown";

const Timer = () => {
    const [updatePageMsg, setUpdatePageMsg] = useState(false);

    const currentDate = new Date();

    function tomorrowDate() {
        const date = new Date();
        const tomorrowFull = new Date(date.setUTCDate(currentDate.getUTCDate() + 1));
        let tomorrow = new Date(tomorrowFull.getUTCFullYear(), tomorrowFull.getUTCMonth(), tomorrowFull.getUTCDate());

        const diffUTC = tomorrow.getTimezoneOffset();

        if (diffUTC < 0) {
            tomorrow = tomorrow.getTime() + Math.abs(diffUTC) * 60 * 1000;
        } else if (diffUTC > 0) {
            tomorrow = tomorrow.getTime() - Math.abs(diffUTC) * 60 * 1000;
        } else if (diffUTC === 0) {
            tomorrow = tomorrow.getTime();
        }

        return tomorrow;
    }

    return (
        <div className="timerWrapper">
            <div className="timerText">Background Image will change in</div>
            <ProgressCountdown deadline={tomorrowDate()} setUpdatePageMsg={setUpdatePageMsg} />
            {updatePageMsg ? <div className="timerReloadMsg">Reload the Page</div> : null}
        </div>
    );
};

export default Timer;
