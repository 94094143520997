import "./MainButtons.scss";

import ftmImg from "../../img/ftm-logo-148.png";
import avaxImg from "../../img/avax-logo-148.png";
import bnbImg from "../../img/bnb-logo-148.png";

import { Button } from "@mui/material";

const MainButtons = () => {
    return (
        <div className="mainButtons">
            <div className="mainButtonsWrapper">
                <div className="mainButtonsText">
                    Become an early adopter today!
                </div>

                <Button
                    variant="contained"
                    className="mainBtn"
                    href="https://pyreswap.finance"
                    target="_blank"
                >
                    Launch Application
                </Button>

                <div className="mainLinksWrapper">
                    <a
                        className="mainLink"
                        href="https://pyreswap.finance/swap?inputCurrency=ETH&outputCurrency=0x9C415370CDf88255b04Cb4a42e782B2Bc493ED3A&chainId=250"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={ftmImg}
                            alt="ftm logo"
                            className="mainNetImg"
                        />
                    </a>
                    <a
                        className="mainLink"
                        href="https://pyreswap.finance/swap?inputCurrency=ETH&outputCurrency=0x2121D5D36AC3E814B2BB15bb3fB57F94190fDe75&chainId=43114"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={avaxImg}
                            alt="avax logo"
                            className="mainNetImg"
                        />
                    </a>
                    <a
                        className="mainLink"
                        href="https://pyreswap.finance/swap?inputCurrency=ETH&outputCurrency=0x955ED211537d9Fd31bDC9A428461e59a4867efDe&chainId=56"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={bnbImg}
                            alt="bnb logo"
                            className="mainNetImg"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MainButtons;
