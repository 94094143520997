import "./Footer.scss";

import emailImg from "../../img/footer/emailIcon.png";
import xImg from "../../img/footer/xIcon.png";
import discordImg from "../../img/footer/discordIcon.png";
import telegramImg from "../../img/footer/telegramIcon.png";
import redditImg from "../../img/footer/redditIcon.png";
import docsImg from "../../img/footer/docsIcon.png";

import Container from "@mui/material/Container";

const Footer = () => {
    return (
        <Container>
            <div className="footerWrapper">
                <div className="footerText">
                    Copyright © Seigniorage Circus {new Date().getFullYear()}
                </div>
                <div className="footerSocialNetworkWrapper">
                    <a
                        className="footerLink"
                        href="mailto:PyreSwap@seignioragecircus.org"
                    >
                        <img alt="email icon" src={emailImg} />
                    </a>
                    <a
                        className="footerLink"
                        href="https://twitter.com/pyreswap"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img alt="x icon" src={xImg} />
                    </a>
                    <a
                        className="footerLink"
                        href="https://discord.gg/YHFwazH3pW"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img alt="discord icon" src={discordImg} />
                    </a>
                    <a
                        className="footerLink"
                        href="https://t.me/pyreswapannouncements"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img alt="telegram icon" src={telegramImg} />
                    </a>
                    <a
                        className="footerLink"
                        href="https://www.reddit.com/r/PyreSwap/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img alt="reddit icon" src={redditImg} />
                    </a>
                    <a
                        className="footerLink"
                        href="https://docs.pyreswap.finance/preface/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img alt="docs icon" src={docsImg} />
                    </a>
                </div>
            </div>
        </Container>
    );
};

export default Footer;
