import React from "react";
import Countdown from "react-countdown";

const ProgressCountdown = ({ deadline, setUpdatePageMsg }) => {
    const countdownRenderer = (countdownProps) => {
        const { days, hours, minutes, seconds } = countdownProps;
        const h = String(days * 24 + hours);
        const m = String(minutes);
        const s = String(seconds);

        if (h === 0 && m === 0 && s === 0) {
            setUpdatePageMsg(true);
        } else {
            setUpdatePageMsg(false);
        }

        return (
            <>
                <div className="timerCountdown">
                    {h.padStart(2, "0")}:{m.padStart(2, "0")}:{s.padStart(2, "0")}
                </div>
            </>
        );
    };
    return (
        <div className="timerCardContentInner">
            <Countdown
                key={new Date().getTime()}
                date={deadline}
                renderer={countdownRenderer}
                precision={3}
                intervalDelay={0}
            />
        </div>
    );
};

export default ProgressCountdown;
