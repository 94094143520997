import "./ScrollBtns.scss";

import down from "../../img/sword-down.png";
import scroll from "../../img/sword-scroll.png";

const ScrollBtns = () => {
    return (
        <div className="scrollBtnsWrapper">
            <img className="scrollBtnsArrow" src={scroll} alt="sword" />
            <img className="scrollBtnsArrow" src={down} alt="sword" />
        </div>
    );
};

export default ScrollBtns;
