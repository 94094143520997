import "./MainPage.scss";
import { useEffect } from "react";
import { UAParser } from "ua-parser-js";

import StartInfo from "../startInfo/StartInfo";
import Timer from "../timer/Timer";
import MainButtons from "../mainButtons/MainButtons";
import ScrollBtns from "../scrollBtns/ScrollBtns";
import Footer from "../footer/Footer";
import ContentSection1 from "../contentSection1/ContentSection1";
import ContentSection2 from "../contentSection2/ContentSection2";
import ContentSection3 from "../contentSection3/ContentSection3";
import ContentSection4 from "../contentSection4/ContentSection4";
import ContentSectionVideo from "../contentSectionVideo/ContentSectionVideo";
import ContentSectionEnd from "../contentSectionEnd/ContentSectionEnd";

const MainPage = () => {
    const bgArr = ["bg-1.png", "bg-2.png", "bg-3.png", "bg-4.png", "bg-5.png", "bg-6.png", "bg-7.png"];

    const changeBg = () => {
        const currentDate = new Date();
        const day = currentDate.getUTCDay();

        const elem = document.getElementById("firstView");
        elem.style.backgroundImage = `url("image/${bgArr[day]}")`;
    };

    const parallaxOffForIphone = () => {
        const parser = new UAParser();
        const OS = parser.getOS();

        if (OS.name === "iOS") {
            const mainBg = document.getElementById("firstView");
            const parallaxElements = document.querySelectorAll(".mainAllParallaxContainers");

            mainBg.style.backgroundAttachment = "scroll";
            parallaxElements.forEach((item) => {
                item.style.backgroundAttachment = "scroll";
            });
        }
    };

    useEffect(() => {
        changeBg();
        parallaxOffForIphone();
    });

    return (
        <>
            <div id="firstView" className="mainParallaxContainer">
                <div className="content">
                    <StartInfo />
                    <Timer />
                    <MainButtons />
                    <ScrollBtns />
                </div>
            </div>

            <div className="mainFrame mainContentSection">
                <ContentSectionVideo />
            </div>

            <div className="mainParallaxContainer mainParallaxContainer1 mainAllParallaxContainers"></div>

            <div className="mainFrame mainContentSection">
                <ContentSection1 />
            </div>

            <div className="mainParallaxContainer mainParallaxContainer2 mainAllParallaxContainers"></div>

            <div className="mainFrame mainContentSection">
                <ContentSection2 />
            </div>

            <div className="mainParallaxContainer mainParallaxContainer4 mainAllParallaxContainers"></div>

            <div className="mainFrame mainContentSection">
                <ContentSection3 />
            </div>

            <div className="mainParallaxContainer mainParallaxContainer3 mainAllParallaxContainers"></div>

            <div className="mainFrame mainContentSection">
                <ContentSection4 />
            </div>

            <div className="mainParallaxContainer mainParallaxContainer5 mainAllParallaxContainers"></div>

            <div className="mainFrame mainContentSection mainContentSectionEnd">
                <ContentSectionEnd />
                <Footer />
            </div>
        </>
    );
};

export default MainPage;
